import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { StructuredText, renderNodeRule } from 'react-datocms';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import { nanoid } from 'nanoid';
import loadScript from 'load-script2';
import DefaultLayout from '../layouts/Default';
import SEO from '../components/SEO';
import Hero from '../components/CMSBlocks/Hero';
import Container from '../components/Container';
import NewsCard from '../components/NewsCard';
import VideoInline from '../components/VideoBlock/inline';

const CharityNewsPage = ({ data }) => {
	const { slug, title, articleImage, subHeading, blogContent, tags } = data?.datoCmsPressRelease;
	const location = useLocation();
	const createTags = () => tags.split(',').map((tag) => tag.trim());
	const [scriptReady, setScriptReady] = React.useState(false);
	React.useEffect(() => {
		const loadVideoScript = () =>
			loadScript('https://player.vimeo.com/api/player.js')
				.then(() => {
					setScriptReady(true);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadVideoScript);
		} else {
			setTimeout(loadVideoScript);

			setTimeout(() => {
				loadVideoScript();
			}, 100);
		}
	}, []);

	return (
		<div>
			<SEO
				path={location.pathname}
				title={title}
				description={subHeading}
				meta={[
					{
						name: `og:image`,
						content: articleImage?.url,
					},
				]}
				isMultisite
			/>

			<Helmet>
				<link rel="canonical" href={`${slug}`} />
			</Helmet>

			<Hero
				bgImg={articleImage?.url}
				title={title}
				breadcrumbs={{
					currentPage: title,
					parent: {
						url: '/peninsula-group-foundation',
						title: 'Peninsula Group Foundation',
					},
				}}
				articleSubHeading={subHeading}
				fullWidth
			/>

			<section>
				<Container>
					<div className="block grid-cols-6 md:grid">
						<div className="col-span-4 my-5">
							{blogContent.value !== null && (
								<StructuredText
									data={blogContent}
									customNodeRules={[
										renderNodeRule(
											isParagraph,
											({
												adapter: { renderNode },
												node,
												children,
												key,
												ancestors,
											}) => {
												if (node.children[0].type === 'inlineItem') {
													return (
														<React.Fragment key={key}>
															{children}
														</React.Fragment>
													);
												}
												if (isRoot(ancestors[0])) {
													return renderNode(
														'p',
														{
															key,
															className:
																'mb-4 text-lg md:mb-5 font-centra-light  last-of-type:mb-0',
														},
														children
													);
												}
												return (
													<React.Fragment key={key}>
														{children}
													</React.Fragment>
												);
											}
										),
									]}
									renderBlock={({ record }) => {
										// eslint-disable-next-line no-underscore-dangle
										switch (record?.__typename) {
											case 'DatoCmsImageBlock':
												return (
													<div className="bg-[#e5e7eb] flex flex-col my-5">
														<img
															src={record.image.url}
															alt={record?.image.alt}
															className="w-auto h-auto object-cover bg-white"
														/>
														{record.credit && (
															<span className="text-xs text-black py-2 px-3 w-full ">
																{record.credit}
															</span>
														)}
													</div>
												);
											case 'DatoCmsQuoteBlock':
												return (
													<blockquote className=" relative p-5 my-5 text-lg font-centra-light bg-brand-500">
														<div className="flex flex-col border-l-[6px] border-brand-200 pl-5">
															<cite className="mb-4  text-xl md:text-2xl font-centra-light">
																{record.titleQuote}
															</cite>
															<span className="text-sm font-bold text-brand-200">
																{record.personAndPosition}
															</span>
														</div>
													</blockquote>
												);
											case 'DatoCmsHyperlinkBlock':
												return (
													<a
														href={record.link}
														target="_blank"
														rel="noreferrer"
														className="text-brand-200 underline my-5"
													>
														{record.linkTitle}
													</a>
												);
											case 'DatoCmsVideoBlock':
												return (
													<div className="bg-[#e5e7eb] flex flex-col my-5">
														{record?.videoLink?.providerUid && (
															<div className="mb-8 lg:mb-0 lg:basis-1/2 aspect-video">
																<VideoInline
																	vimeoId={
																		record.videoLink.providerUid
																	}
																	videoFullWidth
																	videoSubText={record.credit}
																	videoEmbed={record.videoLink}
																	setVideoFinished
																	scriptReady={scriptReady}
																	autoPlay={0}
																/>
															</div>
														)}
													</div>
												);
											default:
												return null;
										}
									}}
								/>
							)}
						</div>

						<div className="col-span-2 p-5">
							<div className="flex-col flex space-y-5">
								<div className="flex flex-col">
									<div className="text-lg font-bold text-brand-200 uppercase ">
										Tags
									</div>
									<div className="flex flex-wrap">
										{tags && (
											<span className="flex flex-wrap mt-4">
												{createTags().map((tag) => (
													<span
														key={tag}
														className="text-xs bg-secondary-100 text-secondary-500 px-2 py-1 mr-2 mb-2"
													>
														# {tag}
													</span>
												))}
											</span>
										)}
									</div>
								</div>
								<div className="flex flex-col gap-4">
									<div className="text-lg font-bold text-brand-200 uppercase ">
										ON THE SAME TOPIC
									</div>
									<div className="block  gap-3">
										{data?.allDatoCmsPressRelease.nodes
											.sort(
												(a, b) =>
													new Date(b.releaseDate) -
													new Date(a.releaseDate)
											)
											.map((newsItem) => (
												<div className="mb-4 bg-white" key={nanoid()}>
													<NewsCard
														key={nanoid()}
														newsItem={newsItem}
														type="charity"
														parentSlug="peninsula-group-foundation"
													/>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</section>
		</div>
	);
};
export default function WrappedCharityNewsPage(props) {
	return (
		<DefaultLayout fullWidth>
			<CharityNewsPage {...props} />
		</DefaultLayout>
	);
}

export const query = graphql`
	query charityPressRelease($id: String) {
		datoCmsPressRelease(slug: { ne: null }, id: { eq: $id }) {
			title
			subHeading
			tags
			id
			originalId
			articleImage {
				url
			}
			blogContent {
				value
				links
				blocks {
					... on DatoCmsQuoteBlock {
						__typename
						id: originalId
						titleQuote
						personAndPosition
						model {
							apiKey
						}
					}
					... on DatoCmsVideoBlock {
						__typename
						id: originalId
						credit
						videoLink {
							height
							provider
							providerUid
							thumbnailUrl
							title
							url
							width
						}
						model {
							apiKey
						}
					}
					... on DatoCmsHyperlinkBlock {
						__typename
						id: originalId
						linkTitle
						link
						model {
							apiKey
						}
					}
					... on DatoCmsImageBlock {
						__typename
						id: originalId
						credit
						image {
							alt
							url
						}
						model {
							apiKey
						}
					}
				}
			}
			slug
		}
		allDatoCmsPressRelease(
			filter: { id: { ne: $id }, pressType: { eq: "Charity" } }
			limit: 3
		) {
			nodes {
				title
				releaseDate
				id
				articleMainImage {
					alt
					url
				}
				pressType
				isArticleInternal
				externalArticleLink
				tags
				slug
			}
		}
	}
`;
